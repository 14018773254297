import * as React from "react"
import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Paper from "@mui/material/Paper"
import { visuallyHidden } from "@mui/utils"
import { Grid, Typography } from "@mui/material"
import { ReactComponent as ViewIcon } from "../../assests/images/view.svg"
import { ReactComponent as EditIcon } from "../../assests/images/Edit.svg"
import { ReactComponent as DeleteIcon } from "../../assests/images/delete.svg"
import { getUserType } from "../../utils/util"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F3F9",
    color: "#000000"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 600
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover
  },
  height: "40px"
}))

interface Data {
  id: number | null;
  email: string | null;
  first_name: string | null;
  last_name: string | null;
  user_type: string | null;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

// function descendingComparator<T>(a: any, b: any, orderBy: keyof any) {

//   if(typeof(a[orderBy]) && typeof(b[orderBy])=='number'){

//     if (b[orderBy] < a[orderBy]) {
//       return -1;
//     }
//     if (b[orderBy] > a[orderBy]) {
//       return 1;
//     }
//     return 0;
//   }else{
//     if(a[orderBy] && b[orderBy]){

//     if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
//       return -1;
//     }
//     if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
//       return 1;
//     }
//     return 0;
//   }
// }

// }

type Order = "asc" | "desc"

function getComparator<Key extends keyof Data>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: string | number | null },
  b: { [key in Key]: string | number | null },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array:T[], comparator: (a: T, b: T) => number):T[] {
  const stabilizedThis = Array.isArray(array) ? array.map((el, index) => [el, index] as [T, number]) : [];
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: keyof Data;
  headers: { id: keyof Data; label: string; disablePadding?: boolean }[];
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, headers } = props
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headers.map(header => (
          <StyledTableCell
            key={header.id}
            align={"center"}
            padding={header.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === header.id ? order : false}
            style={{ fontWeight: "bold" }}
          >
            <TableSortLabel
              active={orderBy === header.id}
              direction={orderBy === header.id ? order : "asc"}
              onClick={createSortHandler(header.id)}
            >
              {header.label}
              {orderBy === header.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface TableProps {
  rows: Data[];
  headers: { id: keyof Data; label: string; disablePadding?: boolean }[];
  handleDelete: (id: any, performance_indicator: any) => void;
  handleView: (details: any) => void;
  handleEdit: (details: any) => void;
  // setRows: any
  // callUsersList: any
}

export default function UsersListTable(tableProps: TableProps) {
  const { rows, headers, handleView, handleEdit, handleDelete } = tableProps
  const [order, setOrder] = React.useState< Order >("asc")
  const [orderBy, setOrderBy] = React.useState<keyof Data>('email');
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  // const [searched, setSearched] = React.useState<string>("");
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  // const requestSearch = (searchedVal: string) => {
  //   const filteredRows = rows.filter((row: any) => {
  //     return row.email.toLowerCase().includes(searchedVal.toLowerCase());
  //   });
  //   setRows(filteredRows);
  //   setSearched(searchedVal);
  // };

  // const cancelSearch = () => {
  //   setSearched("");
  //   callUsersList()
  // };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  )

  return (
    <Box sx={{}}>
      <Paper sx={{ mb: 2 }}>
        {/* <TextField
          label="Search"
          variant="outlined"
          value={searched}
          onChange={(e) => {
            const value = e.target.value;
            if (value.trim() === '') {
              cancelSearch();
            } else {
              requestSearch(value);
            }
          }}
          onBlur={() => {
            if (searched.trim() === '') {
              cancelSearch();
            }
          }}
          InputProps={{
            endAdornment: searched && (
              <IconButton onClick={cancelSearch}>
                <EditIcon />
              </IconButton>
            )
          }}
        /> */}
        <TableContainer>
          <Table
            sx={{ minWidth: "980px" }}
            aria-labelledby="tableTitle"
            size={"small"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={headers}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                return (
                  <StyledTableRow>
                    <TableCell align={"left"} padding="normal">
                      {`${row?.first_name} ${row?.last_name}`}
                    </TableCell>
                    <TableCell align={"left"} padding="normal" sx={{ pl: 3 }}>
                      {" "}
                      {row?.email}
                    </TableCell>
                    <TableCell align={"left"} padding="normal" sx={{ pl: 5 }}>
                      {getUserType(Number(row?.user_type))}
                    </TableCell>
                    <TableCell align={"left"} padding="normal">
                      <Grid
                        container
                        spacing={2}
                        flex-direction={"row"}
                        display={"flex"}
                        alignItems={"left"}
                        justifyContent={"center"}
                      >
                        <Grid item xs={4}>
                          <ViewIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => handleView(row)}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          {
                            <EditIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => handleEdit(row)}
                            />
                          }
                        </Grid>
                        <Grid item xs={4}>
                          <DeleteIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDelete(row.id, row.email)}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                  </StyledTableRow>
                )
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33 * emptyRows
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
            marginLeft: 2
          }}
        >
          <Typography variant="subtitle1" component="div">
            Total Found: {rows.length}
          </Typography>
          <Box></Box>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Paper>
    </Box>
  )
}
