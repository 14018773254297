import { Box } from "@mui/material"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import axios from "axios"
import { APPCONFIG } from "../../app-config"

export default function ShowCopiedImage() {
  const { id } = useParams()
  const [data, setData] = useState({} as any);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${APPCONFIG.axiosBaseUrl}/kpi/api/v1/image/${id}/`
      )
      setData(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (id) {
      fetchData()
    }
  }, [])

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: '100%',
        height: '100%',
        overflow: "auto",
        padding: '2rem 4rem',
        boxSizing: 'border-box',
      }}
    >
      <img src={data?.data?.image_url} alt={"captured_image"} style={{ width: '100%', objectFit: 'contain' }} />
    </Box>
  )
}
