import Button from "@mui/material/Button"
import DialogTitle from "@mui/material/DialogTitle"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Typography from "@mui/material/Typography"
import {
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useEffect, useState } from "react"
import { usertype } from "../../api"

export default function EditPopUp(props: any) {
  const { handleCloseEditPopUp, details, handleSaveUser } = props

  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    user_type: ""
  })

  useEffect(() => {
    setValues({
      ...values,
      first_name: details?.first_name,
      last_name: details?.last_name,
      email: details?.email,
      user_type: details?.user_type
    })
  }, [details])

  const handleChange =
    (prop: string) => (event: { target: { value: any } }) => {
      setValues({ ...values, [prop]: event.target.value })
    }

  const handleRole = (event: SelectChangeEvent) => {
    setValues({ ...values, user_type: event.target.value })
  }

  return (
    <div>
      <Dialog
        onClose={handleCloseEditPopUp}
        open={true}
        PaperProps={{
          sx: {
            width: 400,
            maxHeight: 440,
            borderRadius: "10px"
          }
        }}
      >
        <DialogTitle
          style={{ display: "flex", fontSize: "1rem" }}
          alignSelf={"center"}
          fontWeight={600}
        >
          User
          <IconButton
            aria-label="close"
            onClick={handleCloseEditPopUp}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Divider style={{ width: "100%" }} sx={{ mb: 2 }}></Divider>
          <Typography style={{ fontSize: 14, textAlign: "center" }}>
            <TextField
              id="firstName"
              label="First Name"
              value={values?.first_name}
              fullWidth
              // onChange={handleChange("first_name")}
              sx={{ mb: 2 }}
              InputProps={{
                readOnly: true
              }}
              focused
            />
            <TextField
              id="lastName"
              label="Last Name"
              value={values?.last_name}
              fullWidth
              // onChange={handleChange("last_name")}
              sx={{ mb: 2 }}
              InputProps={{
                readOnly: true
              }}
              focused
            />
            <TextField
              id="email"
              label="Email ID"
              value={values?.email}
              fullWidth
              // onChange={handleChange("email")}
              sx={{ mb: 2 }}
              InputProps={{
                readOnly: true
              }}
              focused
            />
            <FormControl fullWidth>
              <InputLabel id="role-select-label">Role</InputLabel>
              <Select
                labelId="role-select-label"
                id="role-select"
                value={values?.user_type}
                label="Status"
                size="small"
                style={{
                  marginBottom: 0,
                  borderColor: "#FFFFFF",
                  background: "#FFFFFF"
                }}
                onChange={handleRole}
              >
                {usertype &&
                  usertype.map((user: any) => {
                    return <MenuItem value={user.id}>{user.label}</MenuItem>
                  })}
              </Select>
            </FormControl>
          </Typography>
        </DialogContent>
        <DialogActions style={{ display: "flex", alignSelf: "center" }}>
          <Grid container display={"flex"} direction={"row"} sx={{ mb: 2 }}>
            <Grid item xs={5}>
              <Button
                variant="contained"
                style={{ background: "#F8F9FC", color: "#000000" }}
                onClick={handleCloseEditPopUp}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={7}>
              <Button
                onClick={() => handleSaveUser(details?.id, values)}
                variant="contained"
                sx={{ ml: 2 }}
                style={{
                  background: "#000099",
                  width: "120px"
                }}
                disabled={
                  !values?.email ||
                  !values?.first_name ||
                  !values?.last_name ||
                  !values?.user_type
                }
                classes={{ disabled: "custom-disabled-button" }}
              >
                Save User
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
}
