import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from "@mui/material"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useAuthContext } from "../../context/AuthContext"
import { useEffect, useState } from "react"
import { categories, permissions, priorities, statuses } from "../../api"

const CreateKpi = () => {
  const { id } = useParams()
  const nav = useNavigate()
  const location = useLocation()
  const { getSubKpiList, subKpis, addKpi, getKpi, editKpi } = useAuthContext()

  const [values, setValues] = useState({
    title: "",
    kpiNo: "",
    definition: "",
    formula: "",
    selectedKpis: [] as any
  })
  const [category, setCategory] = useState("")
  const [priority, setPriority] = useState("")
  const [status, setStatus] = useState("")
  const [permission, setPermission] = useState("")
  const [kpiData, setKpiData] = useState({} as any)
  const [mode, setMode] = useState("")

  useEffect(() => {
    setMode(location?.state?.mode)
    getSubKpiList()
  }, [])

  useEffect(() => {
    if (id) {
      getKpi(id).then((resp: any) => {
        setKpiData(resp)
      })
    }
  }, [id])

  useEffect(() => {
    if (mode === "edit") {
      setValues({
        ...values,
        title: kpiData?.performance_indicator,
        kpiNo: kpiData?.kpi_number,
        definition: kpiData?.defination,
        formula: kpiData?.formula,
        selectedKpis: kpiData?.sub_kpi_details
      })
      setCategory(kpiData?.category)
      setPriority(kpiData?.priority)
      setStatus(kpiData?.status)
      setPermission(kpiData?.permission)
    }
  }, [mode, kpiData])

  const handleKpiChange = (event: any, value: any[]) => {
    if (mode === "edit" && kpiData?.sub_kpi_details) {
      const selectedKpis = kpiData?.sub_kpi_details.concat(value.filter((kpi: any) => kpiData?.sub_kpi_details.every((prepopulatedKpi: any) => prepopulatedKpi.id !== kpi.id)));
      setValues({ ...values, selectedKpis });
    } else {
      setValues({ ...values, selectedKpis: value });
    }
  }; 

  const handleChange =
    (prop: string) => (event: { target: { value: any } }) => {
      setValues({ ...values, [prop]: event.target.value })
    }

  const handleCategory = (event: SelectChangeEvent) => {
    setCategory(event.target.value)
  }

  const handlePriority = (event: SelectChangeEvent) => {
    setPriority(event.target.value)
  }

  const handleStatus = (event: SelectChangeEvent) => {
    setStatus(event.target.value)
  }

  const handlePermission = (event: SelectChangeEvent) => {
    setPermission(event.target.value)
  }

  const handleCancel = () => {
    nav("/kpi-list")
  }

  const handleSaveKpi = () => {
    const payload = {
      performance_indicator: values.title,
      category: Number(category),
      priority: Number(priority),
      kpi_number: values.kpiNo,
      defination: values.definition,
      formula: values.formula,
      status: status,
      permission: permission,
      sub_kpi: values.selectedKpis?.map((item: any) => item?.id)
    }
    if (mode === "edit") {
      editKpi(id, payload).then(() => {
        nav("/kpi-list")
      })
    } else {
      addKpi(payload).then(() => {
        nav("/kpi-list")
      })
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          px: 2,
          ml: 30,
          mr: 5,
          mt: 15,
          mb: 2,
          backgroundColor: "#F8F9FC"
        }}
      >
        <Grid container display={"flex"} flexDirection={"row"} sx={{ mt: 1 }}>
          <Grid item xs={2} display={"flex"} justifyContent={"center"}>
            <h2>
              {mode === "edit" ? "Edit KPI" : "New KPI"}
            </h2>
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              fullWidth
              label="Title/Preformance Indicator"
              className="text-field"
              id="title"
              size="small"
              name="title"
              autoFocus
              style={{ background: "#FFFFFF" }}
              value={values.title}
              onChange={handleChange("title")}
            />
          </Grid>
          <Grid container display={"flex"} sx={{ mt: 1 }}>
            <Grid item xs={6} sx={{ pr: 1 }}>
              <FormControl fullWidth>
                <InputLabel id="category-select-label">Category</InputLabel>
                <Select
                  labelId="category-select-label"
                  id="category-select"
                  value={category}
                  label="Category"
                  size="small"
                  style={{
                    marginBottom: 0,
                    borderColor: "#FFFFFF",
                    background: "#FFFFFF"
                  }}
                  onChange={handleCategory}
                >
                  {categories &&
                    categories.map((category: any) => {
                      return (
                        <MenuItem value={category.id}>
                          {category.label}
                        </MenuItem>
                      )
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="priority-select-label">Priority</InputLabel>
                <Select
                  labelId="priority-select-label"
                  id="priority-select"
                  value={priority}
                  label="priority"
                  size="small"
                  style={{
                    marginBottom: 0,
                    borderColor: "#FFFFFF",
                    background: "#FFFFFF"
                  }}
                  onChange={handlePriority}
                >
                  {priorities &&
                    priorities.map((priority: any) => {
                      return (
                        <MenuItem value={priority.id}>
                          {priority.label}
                        </MenuItem>
                      )
                    })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              fullWidth
              label="KPI #"
              className="text-field"
              id="kpiNo"
              size="small"
              name="kpiNo"
              autoFocus
              style={{ background: "#FFFFFF" }}
              value={values.kpiNo}
              onChange={handleChange("kpiNo")}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 1 }}>
            <TextField
              fullWidth
              multiline
              id="definition"
              rows={3}
              maxRows={5}
              autoFocus
              label={"Definition"}
              value={values.definition}
              style={{ background: "#FFFFFF" }}
              onChange={handleChange("definition")}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <TextField
              fullWidth
              multiline
              id="formula"
              rows={3}
              maxRows={5}
              autoFocus
              label={"Formula"}
              value={values.formula}
              style={{ background: "#FFFFFF" }}
              onChange={handleChange("formula")}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="status-select-label">Status</InputLabel>
              <Select
                labelId="status-select-label"
                id="status-select"
                value={status}
                label="Status"
                size="small"
                disabled={Number(status) === 2 ? true : false}
                style={{
                  marginBottom: 0,
                  borderColor: "#FFFFFF",
                  background: "#FFFFFF"
                }}
                onChange={handleStatus}
              >
                {statuses &&
                  statuses.map((status: any) => {
                    return <MenuItem value={status.id}>{status.label}</MenuItem>
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="permission-select-label">Permission</InputLabel>
              <Select
                labelId="permission-select-label"
                id="permission-select"
                value={permission}
                label="Permission"
                size="small"
                style={{
                  marginBottom: 0,
                  borderColor: "#FFFFFF",
                  background: "#FFFFFF"
                }}
                onChange={handlePermission}
              >
                {permissions &&
                  permissions.map((permission: any) => {
                    return (
                      <MenuItem value={permission.id}>
                        {permission.label}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Autocomplete
              multiple
              id="tags-outlined"
              options={subKpis || []}
              getOptionLabel={(option: any) => option?.name}
              value={values?.selectedKpis || []}
              onChange={handleKpiChange}
              filterSelectedOptions
              renderInput={params => (
                <TextField
                  {...params}
                  label="Sub KPI"
                  placeholder="Select Sub KPI"
                  style={{ background: "#FFFFFF" }}
                />
              )}
            />
          </Grid>
          <Grid container display={"flex"} sx={{ mt: 2, mb: 2 }}>
            <Grid
              item
              xs={6}
              sx={{ pr: 1 }}
              justifyContent={"flex-end"}
              display={"flex"}
            >
              <Button
                onClick={handleCancel}
                variant="contained"
                style={{ background: "#F8F9FC", color: "#000000" }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} display={"flex"}>
              <Button
                onClick={handleSaveKpi}
                variant="contained"
                style={{ background: "#000099" }}
                disabled={
                  !values.definition ||
                  !values.formula ||
                  !values.kpiNo ||
                  !values.title ||
                  !values.selectedKpis.length ||
                  !category ||
                  !priority ||
                  !status ||
                  !permission
                }
                classes={{ disabled: "custom-disabled-button" }}
              >
                Save KPI
              </Button>{" "}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default CreateKpi
