import { Grid, Typography, Box } from "@mui/material"
import { toast } from "react-toastify"
import { useAuthContext } from "../../context/AuthContext"
import { useEffect, useState } from "react"
import UsersListTable from "./usersListTable"
import ViewPopUp from "./viewPopup"
import EditPopUp from "./editPopup"
import DeletePopUp from "./deletePopup"

const UsersList = () => {
  const { getUserList, editUser, deleteUser } = useAuthContext()
  const [usersList, setUsersList] = useState([])
  const [openViewPopUp, setOpenViewPopUp] = useState({
    open: false,
    details: {}
  })
  const [openEditPopUp, setOpenEditPopUp] = useState({
    open: false,
    details: {}
  })
  const [openDeletePopUp, setOpenDeletePopUp] = useState({
    open: false,
    email: "",
    userId: null
  })

  useEffect(() => {
    getUserList().then((resp: any) => {
      setUsersList(resp)
    })
  }, [])

  interface Data {
    id: number | null;
    email: string | null;
    first_name: string | null;
    last_name: string | null;
    user_type: string | null;
  }

  interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
  }

  const headCells: HeadCell[] = [
    {
      id: "first_name",
      numeric: false,
      disablePadding: true,
      label: "Name"
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "Email"
    },
    {
      id: "user_type",
      numeric: false,
      disablePadding: false,
      label: "Role"
    },
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "Action"
    }
  ]

  const handleViewRow = (row: any) => {
    setOpenViewPopUp({
      ...openDeletePopUp,
      open: true,
      details: row
    })
  }

  const handleCloseViewPopUp = () => {
    setOpenViewPopUp({
      ...openDeletePopUp,
      open: false,
      details: {}
    })
  }

  const handleCloseEditPopUp = () => {
    setOpenEditPopUp({
      ...openEditPopUp,
      open: false,
      details: {}
    })
  }

  const handleEditRow = (row: any) => {
    setOpenEditPopUp({
      ...openEditPopUp,
      open: true,
      details: row
    })
  }

  const handleDeleteRow = (id: any, email: any) => {
    setOpenDeletePopUp({
      ...openDeletePopUp,
      open: true,
      email: email,
      userId: id
    })
  }

  const handleCloseDeletePopUp = () => {
    setOpenDeletePopUp({
      ...openDeletePopUp,
      open: false,
      email: "",
      userId: null
    })
  }

  const handleSaveUser = (id: any, details: any) => {
    editUser(id, details).then(() => {
      setOpenEditPopUp({
        ...openEditPopUp,
        open: false,
        details: {}
      })
      toast.success("User sucessfully edited")
      getUserList().then((response: any) => {
        setUsersList(response)
      })
    })
  }

  const handleDeleteUser = (userId: any) => {
    deleteUser(userId).then(() => {
      setOpenDeletePopUp({
        ...openDeletePopUp,
        open: false,
        email: "",
        userId: null
      })
      toast.success("User sucessfully deleted")
      getUserList().then((response: any) => {
        setUsersList(response)
      })
    })
  }

  return (
     <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          px: 2,
          ml: 30,
          mt: 15,
          mb: 2,
          backgroundColor: "#F8F9FC"
        }}
      >
      <Grid container display={"flex"} flexDirection={"row"}>
        <Grid item xs={6} display={"flex"} justifyContent={"flex-start"}>
          <h2>User List</h2>
        </Grid>
        <Grid container display={"flex"} flexDirection={"row"}>
          <Grid item xs={12} display={"flex"} justifyContent={"flex-start"}>
            <Box padding={2}>
              {usersList && usersList.length ? (
                <UsersListTable
                  rows={usersList}
                  headers={headCells}
                  handleDelete={(id, email) => handleDeleteRow(id, email)}
                  handleView={details => handleViewRow(details)}
                  handleEdit={details => handleEditRow(details)}
                  // setRows={(rows: any) => {setUsersList(rows)}}
                  // callUsersList={() => {
                  //   getUserList().then((resp: any) => {
                  //     setUsersList(resp)
                  //   })
                  // }}
                />
              ) : null}
            </Box>
          </Grid>
        </Grid>
        {openViewPopUp?.open && (
          <ViewPopUp
            open={true}
            details={openViewPopUp?.details}
            handleCloseViewPopUp={handleCloseViewPopUp}
          ></ViewPopUp>
        )}
        {openEditPopUp?.open && (
          <EditPopUp
            open={true}
            details={openEditPopUp?.details}
            handleCloseEditPopUp={handleCloseEditPopUp}
            handleSaveUser={handleSaveUser}
          ></EditPopUp>
        )}
        {openDeletePopUp?.open && (
          <DeletePopUp
            open={true}
            userId={openDeletePopUp?.userId}
            email={openDeletePopUp?.email}
            handleCloseDeletePopUp={handleCloseDeletePopUp}
            handleDelete={handleDeleteUser}
          ></DeletePopUp>
        )}
      </Grid>
    </Box>
  )
}

export default UsersList
