import { Grid, Typography, Box } from "@mui/material"
import { toast } from "react-toastify"
import { useAuthContext } from "../../context/AuthContext"
import { useEffect, useState } from "react"
import FeedbackListTable from "./feedbackListTable"
import ViewPopUp from "./viewPopup"
import ReplyPopUp from "./replyPopup"

const FeedbackList = () => {
  const { getFeedbackList, replyFeedback } = useAuthContext()
  const [feedbackList, setFeedbackList] = useState([])
  const [openViewPopUp, setOpenViewPopUp] = useState({
    open: false,
    details: {}
  })
  const [openReplyPopUp, setOpenReplyPopUp] = useState({
    open: false,
    details: {}
  })
  const [openDeletePopUp, setOpenDeletePopUp] = useState({
    open: false,
    email: "",
    userId: null
  })

  useEffect(() => {
    getFeedbackList().then((resp: any) => {
      setFeedbackList(resp)
    })
  }, [])

  interface Data {
    id: number | null;
    email: string | null;
    subject: string | null;
    text: string | null;
  }

  interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
  }

  const headCells: HeadCell[] = [
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "Email"
    },
    {
      id: "subject",
      numeric: false,
      disablePadding: true,
      label: "Subject"
    },
    {
      id: "text",
      numeric: false,
      disablePadding: false,
      label: "Message"
    },
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "Action"
    }
  ]

  const handleViewRow = (row: any) => {
    setOpenViewPopUp({
      ...openDeletePopUp,
      open: true,
      details: row
    })
  }

  const handleCloseViewPopUp = () => {
    setOpenViewPopUp({
      ...openDeletePopUp,
      open: false,
      details: {}
    })
  }

  const handleCloseReplyPopUp = () => {
    setOpenReplyPopUp({
      ...openReplyPopUp,
      open: false,
      details: {}
    })
  }

  const handleReplyRow = (row: any) => {
    setOpenReplyPopUp({
      ...openReplyPopUp,
      open: true,
      details: row
    })
  }

  const handleSendReply = (id: any, details: any) => {
    replyFeedback(details).then(() => {
      setOpenReplyPopUp({
        ...openReplyPopUp,
        open: false,
        details: {}
      })
      toast.success("Feedback reply sucessfully sent")
      getFeedbackList().then((response: any) => {
        setFeedbackList(response)
      })
    })
  }

  return (
    <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          px: 2,
          ml: 30,
          mb: 2,
          backgroundColor: "#F8F9FC"
        }}
      >
      <Grid container display={"flex"} flexDirection={"row"} sx={{ mt: 15 }}>
        <Grid item xs={7} display={"flex"} justifyContent={"center"}>
          <h2>User Feedback</h2>
        </Grid>
        <Grid container display={"flex"} flexDirection={"row"}>
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <Box padding={2} ml={25}>
              {feedbackList && feedbackList.length ? (
                <FeedbackListTable
                  rows={feedbackList}
                  headers={headCells}
                  handleView={details => handleViewRow(details)}
                  handleReply={details => handleReplyRow(details)}
                />
              ) : null}
            </Box>
          </Grid>
        </Grid>
        {openViewPopUp?.open && (
          <ViewPopUp
            open={true}
            details={openViewPopUp?.details}
            handleCloseViewPopUp={handleCloseViewPopUp}
          ></ViewPopUp>
        )}
        {openReplyPopUp?.open && (
          <ReplyPopUp
            open={true}
            details={openReplyPopUp?.details}
            handleCloseReplyPopUp={handleCloseReplyPopUp}
            handleSendReply={handleSendReply}
          ></ReplyPopUp>
        )}
      </Grid>
    </Box>
  )
}

export default FeedbackList
