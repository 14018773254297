import Button from "@mui/material/Button"
import DialogTitle from "@mui/material/DialogTitle"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Typography from "@mui/material/Typography"
import { Divider, Grid, IconButton, TextField } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useEffect, useState } from "react"

export default function ReplyPopUp(props: any) {
  const { handleCloseReplyPopUp, details, handleSendReply } = props

  const [values, setValues] = useState({
    subject: "",
    text: "",
    email: ""
  })

  useEffect(() => {
    setValues({
      ...values,
      subject: details?.subject,
      email: details?.email
    })
  }, [details])

  const handleChange =
    (prop: string) => (event: { target: { value: any } }) => {
      setValues({ ...values, [prop]: event.target.value })
    }

  return (
    <div>
      <Dialog
        onClose={handleCloseReplyPopUp}
        open={true}
        PaperProps={{
          sx: {
            width: 400,
            maxHeight: 440,
            borderRadius: "10px"
          }
        }}
      >
        <DialogTitle
          style={{ display: "flex", fontSize: "1rem" }}
          alignSelf={"center"}
          fontWeight={600}
        >
          User Feedback
          <IconButton
            aria-label="close"
            onClick={handleCloseReplyPopUp}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Divider style={{ width: "100%" }} sx={{ mb: 2 }}></Divider>
          <Typography style={{ fontSize: 14, textAlign: "center" }}>
            <TextField
              id="email"
              label="Email Id"
              value={values?.email}
              fullWidth
              sx={{ mb: 2 }}
              InputProps={{
                readOnly: true
              }}
              focused
            />
            <TextField
              id="subject"
              label="Subject"
              value={values?.subject}
              fullWidth
              sx={{ mb: 2 }}
              InputProps={{
                readOnly: true
              }}
              focused
            />
            <TextField
              id="reply"
              label="Reply"
              value={values?.text}
              fullWidth
              multiline
              rows={3}
              maxRows={5}
              onChange={handleChange("text")}
              sx={{ mb: 2 }}
            />
          </Typography>
        </DialogContent>
        <DialogActions style={{ display: "flex", alignSelf: "center" }}>
          <Grid container display={"flex"} direction={"row"} sx={{ mb: 2 }}>
            <Grid item xs={4}>
              <Button
                variant="contained"
                style={{ background: "#F8F9FC", color: "#000000" }}
                onClick={handleCloseReplyPopUp}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={8}>
              <Button
                onClick={() => handleSendReply(details?.id, values)}
                variant="contained"
                sx={{ ml: 4 }}
                style={{
                  background: "#000099"
                }}
                disabled={!values?.text}
                classes={{ disabled: "custom-disabled-button" }}
              >
                Send Reply
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
}
