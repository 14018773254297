import { ThemeProvider } from "@emotion/react"
import { createTheme, Grid, Box, TextField } from "@mui/material"
import { DATA_TYPE_PERCENTAGE, subKpiType } from "../../api"
import {
  checkShowCompanyAndShowCustomer,
  getFillRate,
  getUserData
} from "../../utils/util"

const ViewKpiReportData = (props: any) => {
  const {
    selectedKpi,
    subKpi,
    key,
    date,
    reportData,
    selectedCompany,
    selectedCustomer
  } = props
  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              borderRadius: 0
            },
            width: "100%"
          }
        }
      }
    }
  })

  const months = [
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
    "Jan",
    "Feb",
    "Mar"
  ]

  const getMonthlyData = (month: string, subKpi: any) => {
    // Extract the year from the start date
    const [startYear] = date?.startDate?.split("-")
    // Extract the year and month from the end date
    const [endYear] = date?.endDate?.split("-")
    // Generate the dynamic date based on the selected month
    let generatedDate: string = ""

    if (month === "Apr") {
      generatedDate = `${startYear}-04-01`
    } else if (month === "May") {
      generatedDate = `${startYear}-05-01`
    } else if (month === "Jun") {
      generatedDate = `${startYear}-06-01`
    } else if (month === "Jul") {
      generatedDate = `${startYear}-07-01`
    } else if (month === "Aug") {
      generatedDate = `${startYear}-08-01`
    } else if (month === "Sep") {
      generatedDate = `${startYear}-09-01`
    } else if (month === "Oct") {
      generatedDate = `${startYear}-10-01`
    } else if (month === "Nov") {
      generatedDate = `${startYear}-11-01`
    } else if (month === "Dec") {
      generatedDate = `${startYear}-12-01`
    } else if (month === "Jan") {
      generatedDate = `${endYear}-01-01`
    } else if (month === "Feb") {
      generatedDate = `${endYear}-02-01`
    } else if (month === "Mar") {
      generatedDate = `${endYear}-03-01`
    }

    let fill_rate = null
    reportData.map((report: any) => {
      const [showCompany, showCustomer] = checkShowCompanyAndShowCustomer(
        selectedKpi,
        subKpi,
        getUserData()?.user_type
      )
      if (
        report?.date === generatedDate &&
        report?.sub_kpi === subKpi.id &&
        (!selectedCustomer ||
          !showCustomer ||
          selectedCustomer?.toString() === report?.customer?.toString()) &&
        (!selectedCompany ||
          !showCompany ||
          selectedCompany?.toString() === report?.company?.toString())
      ) {
        fill_rate = getFillRate(subKpi, report)
      }
    })
    return fill_rate
  }

  const getSubKpiType = () => {
    if (subKpi.type === 1) {
      return subKpiType[0].label
    } else if (subKpi.type === 2) {
      return subKpiType[1].label
    } else return subKpiType[2].label
  }

  return (
    <>
      <Grid container display={"flex"} flexDirection={"row"} sx={{ mt: 1 }}>
        <ThemeProvider theme={theme}>
          <Box border="1px solid #E0E0E0">
            <Grid container>
              <Grid item xs={12} style={{ background: "#D2D2D2" }}>
                <Grid
                  container
                  flex-direction={"column"}
                  alignItems={"center"}
                  sx={{ pb: 1 }}
                >
                  <Grid
                    item
                    xs={5}
                    style={{
                      fontSize: 20
                    }}
                  >
                    <h5>{subKpi.name}</h5>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{
                      fontSize: 18,
                      display: "flex",
                      justifyContent: "flex-start"
                    }}
                  >
                    {getSubKpiType()}
                  </Grid>
                </Grid>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  {months.map((month, index) => (
                    <div
                      key={month}
                      style={{
                        padding: "0px",
                        fontWeight: 500,
                        fontSize: 16,
                        borderBottom: `1px solid ${
                          index === months.length - 1 ? "transparent" : "none"
                        }`
                      }}
                    >
                      {month}
                    </div>
                  ))}
                </div>
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {months.map((month, index) => (
                    <div
                      key={month}
                      style={{
                        padding: "0px",
                        borderBottom: `1px solid ${
                          index === months.length - 1 ? "transparent" : "none"
                        }`
                      }}
                    >
                      <TextField
                        style={{ borderRadius: "0px" }}
                        variant="outlined"
                        InputProps={{
                          endAdornment:
                            subKpi?.data_type === DATA_TYPE_PERCENTAGE
                              ? "%"
                              : undefined
                        }}
                        fullWidth
                        aria-readonly
                        disabled
                        value={getMonthlyData(month, subKpi)}
                      />
                    </div>
                  ))}
                </div>
              </Grid>
            </Grid>
          </Box>
        </ThemeProvider>
      </Grid>
    </>
  )
}

export default ViewKpiReportData
