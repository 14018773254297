import Button from "@mui/material/Button"
import DialogTitle from "@mui/material/DialogTitle"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Typography from "@mui/material/Typography"
import { Divider, Grid, IconButton, TextField } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

export default function ViewPopUp(props: any) {
  const { handleCloseViewPopUp, details } = props

  return (
    <div>
      <Dialog
        onClose={handleCloseViewPopUp}
        open={true}
        PaperProps={{
          sx: {
            width: 400,
            maxHeight: 420,
            borderRadius: "10px"
          }
        }}
      >
        <DialogTitle
          style={{ display: "flex", fontSize: "1rem" }}
          alignSelf={"center"}
          fontWeight={600}
        >
          User Feedback
          <IconButton
            aria-label="close"
            onClick={handleCloseViewPopUp}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Divider style={{ width: "100%" }} sx={{ mb: 2 }}></Divider>
          <Typography style={{ fontSize: 14, textAlign: "center" }}>
            <TextField
              id="email"
              label="Email ID"
              value={details?.email}
              InputProps={{
                readOnly: true
              }}
              focused
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              id="subject"
              label="Subject"
              value={details?.subject}
              fullWidth
              sx={{ mb: 2 }}
              InputProps={{
                readOnly: true
              }}
              focused
            />
            <TextField
              id="message"
              label="Message"
              value={details?.text}
              multiline
              rows={3}
              maxRows={5}
              fullWidth
              sx={{ mb: 2 }}
              InputProps={{
                readOnly: true
              }}
              focused
            />
          </Typography>
        </DialogContent>
        <DialogActions style={{ display: "flex", alignSelf: "center" }}>
          <Grid container display={"flex"} direction={"column"}>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                style={{ background: "#000099" }}
                onClick={handleCloseViewPopUp}
              >
                Ok
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
}
