import { Box, Button, Grid, TextField, Typography } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"
import { useAuthContext } from "../../context/AuthContext"
import { useEffect, useState } from "react"
import {
  getCategory,
  getPermission,
  getPriority,
  getStatus
} from "../../utils/util"

const ViewKpi = () => {
  const nav = useNavigate()
  const location = useLocation()
  const [kpiId, setKpiId] = useState(null)
  const { getKpi } = useAuthContext()
  const [kpiData, setKpiData] = useState({} as any)

  useEffect(() => {
    setKpiId(location.state?.kpiId)
  }, [])

  useEffect(() => {
    if (kpiId) {
      getKpi(kpiId).then((resp: any) => {
        setKpiData(resp)
      })
    }
  }, [kpiId])

  const getSubKpiNames = () => {
    return (
      kpiData?.sub_kpi_details &&
      kpiData?.sub_kpi_details.length &&
      kpiData?.sub_kpi_details.map((subKpi: any) => subKpi.name)
    )
  }

  const handleCancel = () => {
    nav("/kpi-list")
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          px: 2,
          ml: 30,
          mr: 5,
          mt: 15,
          mb: 2,
          backgroundColor: "#F8F9FC"
        }}
      >
        <Grid container display={"flex"} flexDirection={"row"} sx={{ mt: 1 }}>
          <Grid item xs={2}>
            <Typography sx={{ fontSize: 32 }}>View KPI</Typography>
          </Grid>
          <Grid item xs={12} sx={{ mt: 3 }}>
            <TextField
              focused
              fullWidth
              label="Title/Preformance Indicator"
              className="text-field"
              id="title"
              size="small"
              InputProps={{
                readOnly: true
              }}
              style={{ background: "#FFFFFF" }}
              value={kpiData?.performance_indicator}
            />
          </Grid>
          <Grid container display={"flex"} spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={6}>
              <TextField
                focused
                fullWidth
                label="Category"
                className="text-field"
                id="title"
                size="small"
                InputProps={{
                  readOnly: true
                }}
                style={{ background: "#FFFFFF" }}
                value={getCategory(kpiData?.category)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                focused
                fullWidth
                label="Priority"
                className="text-field"
                size="small"
                InputProps={{
                  readOnly: true
                }}
                style={{ background: "#FFFFFF" }}
                value={getPriority(kpiData?.category)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ mt: 3 }}>
            <TextField
              focused
              fullWidth
              label="KPI #"
              className="text-field"
              size="small"
              InputProps={{
                readOnly: true
              }}
              style={{ background: "#FFFFFF" }}
              value={kpiData?.kpi_number}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 3 }}>
            <TextField
              focused
              fullWidth
              label="Definition"
              className="text-field"
              size="small"
              InputProps={{
                readOnly: true
              }}
              style={{ background: "#FFFFFF" }}
              value={kpiData?.defination}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 3 }}>
            <TextField
              focused
              fullWidth
              multiline
              rows={3}
              maxRows={5}
              label="Formula"
              className="text-field"
              size="small"
              InputProps={{
                readOnly: true
              }}
              style={{ background: "#FFFFFF" }}
              value={kpiData?.formula}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 3 }}>
            <TextField
              focused
              fullWidth
              label="Status"
              className="text-field"
              size="small"
              InputProps={{
                readOnly: true
              }}
              style={{ background: "#FFFFFF" }}
              value={getStatus(kpiData?.status)}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 3 }}>
            <TextField
              focused
              fullWidth
              label="Permission"
              className="text-field"
              size="small"
              InputProps={{
                readOnly: true
              }}
              style={{ background: "#FFFFFF" }}
              value={getPermission(kpiData?.permission)}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 3 }}>
            <TextField
              focused
              multiline
              fullWidth
              label="Sub KPI"
              className="text-field"
              size="small"
              InputProps={{
                readOnly: true
              }}
              style={{ background: "#FFFFFF" }}
              value={getSubKpiNames()?.join(", ")}
            />
            <Grid container display={"flex"} sx={{ mt: 3, mb: 3 }}>
              <Grid
                item
                xs={6}
                sx={{ pr: 1 }}
                justifyContent={"flex-end"}
                display={"flex"}
              >
                <Button
                  onClick={handleCancel}
                  variant="contained"
                  style={{ background: "#F8F9FC", color: "#000000" }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default ViewKpi
