import { ToastContainer } from "react-toastify"
import "./App.css"
import AuthProvider from "./context/AuthContext"
import AppRoute from "./routes/AppRoutes"

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          theme="light"
        ></ToastContainer>
        <AppRoute />
      </AuthProvider>
    </div>
  )
}

export default App
