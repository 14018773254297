import { Navigate, Route, Routes } from "react-router-dom"
import SignIn from "../Components/SignIn/SignIn"
import VerifyEmail from "../Components/Register/VerifyEmail"
import VerifyOtp from "../Components/Register/VerifyOtp"
import EmailVerifySuccess from "../Components/Register/EmailVerifySuccess"
import AddProfile from "../Components/Register/AddProfile"
import ForgotPassword from "../Components/SignIn/ForgotPassword"
import ResetPassword from "../Components/SignIn/ResetPassword"
import PasswordChangeSuccess from "../Components/SignIn/PasswordChangeSuccess"
import CreateKpi from "../Components/KPIReports/createKpi"
import Layout from "../Components/Layout/Layout"
import Navbar from "../Components/navbar/Navbar"
import KpiList from "../Components/KPIReports/KpiList"
import KpiReport from "../Components/ViewReports/kpiReport"
import AddProfileSuccess from "../Components/Register/AddProfileSuccess"
import ViewKpi from "../Components/KPIReports/viewKpi"
import ViewKpiReport from "../Components/ViewReports/viewKpiReport"
import CreateKpiReport from "../Components/ViewReports/createKpiReport"
import GenerateChart from "../Components/GenerateChart/generateChart"
import ViewChart from "../Components/GenerateChart/ViewChart"
import UsersList from "../Components/Users/usersList"
import UserFeedback from "../Components/Feedback/userFeedback"
import ShowCopiedImage from "../Components/GenerateChart/showCopiedImage"
import { useAuthContext } from "../context/AuthContext"
import ScoreCard from "../Components/Scorecard"
import CreateDashboard from '../Components/Dashboard/CreateDashboard'
import ViewDashboard from '../Components/Dashboard/ViewDashboard'

// interface ProtectedRouteProps {
//   path: string;
//   component: React.ComponentType;
//   isAuthenticated: boolean;
// }

// const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
//   path,
//   component: Component,
//   isAuthenticated,
// }) => {
//   console.log(isAuthenticated)
//   if (isAuthenticated) {
//     return <Route path={path} element={<Component />} />;
//   } else {
//     return <Navigate to="/login" replace />;
//   }
// };

const AppRoute = () => {
  const { isAuthenticated } = useAuthContext()
  return (
    <>
      <Layout>
        <Navbar />
      </Layout>
      <Routes>
        <Route path="/login" element={<SignIn />}></Route>
        <Route path="/register" element={<VerifyEmail />}></Route>
        <Route path="/verify-otp" element={<VerifyOtp />}></Route>
        <Route
          path="/email-verify-success"
          element={<EmailVerifySuccess />}
        ></Route>
        <Route path="/add-profile" element={<AddProfile />}></Route>
        <Route path="/register-success" element={<AddProfileSuccess />}></Route>
        <Route path="/forgot-password" element={<ForgotPassword />}></Route>
        <Route path="/reset-password" element={<ResetPassword />}></Route>
        <Route
          path="/password-change-success"
          element={<PasswordChangeSuccess />}
        ></Route>
        <Route path="/kpi-list" element={<KpiList />}></Route>
        <Route path="/create-kpi" element={<CreateKpi />}></Route>
        <Route path="/view-kpi/:id" element={<ViewKpi />}></Route>
        <Route path="/edit-kpi/:id" element={<CreateKpi />}></Route>
        <Route path="/kpi-report" element={<KpiReport />}></Route>
        <Route path="/kpi-report/view/:id" element={<ViewKpiReport />}></Route>
        <Route
          path="/kpi-report/edit/:id"
          element={<CreateKpiReport />}
        ></Route>

        <Route path="/generate-scorecard" element={<ScoreCard />}></Route>
        <Route path="/create-dashboard" element={<CreateDashboard />}></Route>
        <Route path="/view-dashboard" element={<ViewDashboard />}></Route>

        <Route path="/generate-chart" element={<GenerateChart />}></Route>
        <Route path="/users" element={<UsersList />}></Route>
        <Route path="/feedback" element={<UserFeedback />}></Route>
        <Route path="/view-chart" element={<ViewChart />}></Route>
        {/* <Route element={<ProtectedRoute path="/kpi-list" component={KpiList} isAuthenticated={isAuthenticated} />} />
        <Route element={<ProtectedRoute path="/create-kpi" component={CreateKpi} isAuthenticated={isAuthenticated} />} />
        <Route element={<ProtectedRoute path="/view-kpi/:id" component={ViewKpi} isAuthenticated={isAuthenticated} />} />
        <Route element={<ProtectedRoute path="/edit-kpi/:id" component={CreateKpi} isAuthenticated={isAuthenticated} />} />
        <Route element={<ProtectedRoute path="/kpi-report" component={KpiReport} isAuthenticated={isAuthenticated} />} />
        <Route element={<ProtectedRoute path="/kpi-report/view/:id" component={ViewKpiReport} isAuthenticated={isAuthenticated} />} />
        <Route element={<ProtectedRoute path="/kpi-report/edit/:id" component={CreateKpiReport} isAuthenticated={isAuthenticated} />} />
        <Route element={<ProtectedRoute path="/generate-chart" component={GenerateChart} isAuthenticated={isAuthenticated} />} />
        <Route element={<ProtectedRoute path="/users" component={UsersList} isAuthenticated={isAuthenticated} />} />
        <Route element={<ProtectedRoute path="/feedback" component={UserFeedback} isAuthenticated={isAuthenticated} />} />
        <Route element={<ProtectedRoute path="/view-chart" component={ViewChart} isAuthenticated={isAuthenticated} />} /> */}
        <Route path="/image/:id" element={<ShowCopiedImage />}></Route>
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </>
  )
}

export default AppRoute
