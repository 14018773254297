import { useState } from "react"
import { ReactComponent as AppLogo } from "../../assests/images/AppLogo.svg"
import "./SignIn.css"
import EyeOn from "../../assests/images/eye_on.svg"
import EyeOff from "../../assests/images/eye_off.svg"
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  ThemeProvider,
  Typography,
  createTheme
} from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useAuthContext } from "../../context/AuthContext"
import { getUserData } from "../../utils/util"

const SignIn = () => {
  const nav = useNavigate()
  const { userLogin } = useAuthContext()
  const theme = createTheme({
    palette: {
      secondary: {
        main: "#000099"
      }
    }
  })

  const [values, setValues] = useState({
    email: "",
    password: "",
    showPassword: false
  })

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleClickShowPassword = () =>
    setValues({ ...values, showPassword: !values.showPassword })
  const handleMouseDownPassword = (event: any) => event.preventDefault()

  const handleChange =
    (prop: string) => (event: { target: { value: any } }) => {
      setValues({ ...values, [prop]: event.target.value })
    }

  const handleSignIn = async (event: any) => {
    event.preventDefault()
    // Validate the form inputs
    const validationErrors: { [key: string]: string } = {}
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (values.email.trim() === "") {
      validationErrors.username = "Email is required"
    } else if (!emailRegex.test(values.email)) {
      validationErrors.username = "Invalid email format"
    }

    // Password validation
    if (values.password.trim() === "") {
      validationErrors.password = "Password is required"
    } else if (values.password.length < 8) {
      validationErrors.password =
        "Password should be at least 8 characters long"
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
    } else {
      userLogin({ username: values.email, password: values.password }).then(
        () => {
          getUserData()?.user_type === 1 ?
          nav("/kpi-list") : nav("/kpi-report") }
      )
    }
  }

  const navigateToRegister = () => {
    nav("/register")
  }

  const navigateToForgotPassword = () => {
    nav("/forgot-password")
  }

  return (
    <>
      <div className="page-container">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
            px: 2
          }}
        >
          <Paper
            sx={{
              p: 2,
              textAlign: "center",
              maxWidth: "400px",
              width: "100%"
            }}
            style={{
              borderColor: "#000099",
              borderWidth: 2,
              borderStyle: "solid",
              borderRadius: 8
            }}
          >
            <AppLogo />
            <Box className="sign-in-title">Login</Box>
            <Typography
              sx={{ mr: 4, ml: 5, mt: 3 }}
              style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: 400,
                fontSize: "16px",
                textAlign: "initial"
              }}
            >
              Login with your credential that you enter during registration
            </Typography>
            <div className="grid-container">
              <Grid
                container
                direction={"column"}
                justifyItems={"center"}
                alignItems={"center"}
              >
                <Grid item xs={12}>
                  <TextField
                    label="Email I'd"
                    className="sign-in-field"
                    variant="outlined"
                    size="small"
                    style={{ width: 320, marginBottom: 0 }}
                    value={values.email}
                    onChange={handleChange("email")}
                    margin="normal"
                    required
                    error={!!errors.username}
                    helperText={errors.username}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Password"
                    variant="outlined"
                    className="sign-in-field"
                    style={{
                      width: 320,
                      marginBottom: 0,
                      borderColor: "#FFFFFF"
                    }}
                    type={values.showPassword ? "text" : "password"}
                    value={values.password}
                    onChange={handleChange("password")}
                    margin="normal"
                    size="small"
                    required
                    error={!!errors.password}
                    helperText={errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <img src={EyeOn} />
                            ) : (
                              <img src={EyeOff} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid
                  item
                  className="grid-item"
                  xs={6}
                  alignSelf={"flex-end"}
                  sx={{ mr: 5, mt: 2 }}
                >
                  <Typography
                    style={{
                      cursor: "pointer",
                      color: "#000099",
                      textDecoration: "underline"
                    }}
                    onClick={navigateToForgotPassword}
                  >
                    Forgot Password?
                  </Typography>
                </Grid>
              </Grid>
            </div>

            <Grid
              container
              spacing={2}
              direction={"column"}
              alignItems={"center"}
            >
              <br />
              <Grid item xs={12}>
                <ThemeProvider theme={theme}>
                  <Button
                    variant={"contained"}
                    color="secondary"
                    onClick={handleSignIn}
                    style={{
                      width: 320,
                      color: "#FFFFFF",
                      textTransform: "none"
                    }}
                  >
                    Login
                  </Button>
                </ThemeProvider>
              </Grid>
            </Grid>
            <Grid container display={"flex"} justifyContent={"flex-start"}>
              <Grid item sx={{ ml: 5, mt: 2 }}>
                <span> Don't have an account?</span>{" "}
                <span
                  style={{
                    cursor: "pointer",
                    color: "#000099",
                    textDecoration: "underline"
                  }}
                  onClick={navigateToRegister}
                >
                  Register
                </span>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </div>
    </>
  )
}

export default SignIn
