import { useEffect, useState } from "react"
import { ReactComponent as AppLogo } from "../../assests/images/AppLogo.svg"
import EyeOn from "../../assests/images/eye_on.svg"
import EyeOff from "../../assests/images/eye_off.svg"
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  ThemeProvider,
  Typography,
  createTheme
} from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"
import { useAuthContext } from "../../context/AuthContext"
import "../SignIn/SignIn.css"

const VerifyOtp = () => {
  const nav = useNavigate()
  const location = useLocation()
  const {
    sendEmailOtp,
    resetSendEmailOtp,
    confirmEmailOtp,
    resetConfirmEmailOtp
  } = useAuthContext()
  const theme = createTheme({
    palette: {
      secondary: {
        main: "#000099"
      }
    }
  })

  const [values, setValues] = useState({
    otp: null,
    showPassword: false
  })

  const [email, setEmail] = useState("")
  const [from, setFrom] = useState("")

  const handleChange =
    (prop: string) => (event: { target: { value: any } }) => {
      setValues({ ...values, [prop]: event.target.value })
    }

  useEffect(() => {
    setEmail(location.state?.email)
    setFrom(location.state?.from)
  }, [])

  const handleClickShowPassword = () =>
    setValues({ ...values, showPassword: !values.showPassword })
  const handleMouseDownPassword = (event: any) => event.preventDefault()

  const handleSendEmailOtp = () => {
    if (from === "forgot-password") {
      resetSendEmailOtp({ email }).then(() => nav("/verify-otp"))
    } else {
      sendEmailOtp({ email }).then(() => nav("/verify-otp"))
    }
  }

  const handleSignIn = async (event: any) => {
    const payload = { email, otp: values.otp }
    if (from === "forgot-password") {
      resetConfirmEmailOtp(payload).then((resp: any) => {
        nav("/reset-password", {
          state: { email, uid: resp.uid, token: resp.token }
        })
      })
    } else {
      confirmEmailOtp(payload).then(() => {
        nav("/email-verify-success", {
          state: { email }
        })
      })
    }
  }

  return (
    <>
      <div className="page-container">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
            px: 2
          }}
        >
          <Paper
            sx={{
              p: 2,
              textAlign: "center",
              maxWidth: "400px",
              width: "100%"
            }}
            style={{
              borderColor: "#000099",
              borderWidth: 2,
              borderStyle: "solid",
              borderRadius: 8
            }}
          >
            <div className="grid-container">
              <Grid
                container
                spacing={2}
                direction={"column"}
                justifyItems={"center"}
                alignItems={"center"}
              >
                <Grid item xs={2}>
                  <AppLogo />
                  <Box className="sign-in-title">Verification</Box>
                  <Typography
                    display={"flex"}
                    sx={{ mt: 2 }}
                    style={{
                      fontWeight: 400,
                      fontSize: "16px",
                      whiteSpace: "pre-line"
                    }}
                  >
                    {
                      "Please enter the verification code that was \nsent to your email address."
                    }
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="verification code"
                    variant="outlined"
                    className="sign-in-field"
                    style={{
                      width: 320,
                      marginBottom: 0,
                      borderColor: "#FFFFFF"
                    }}
                    type={values.showPassword ? "text" : "password"}
                    value={values.otp}
                    onChange={handleChange("otp")}
                    margin="normal"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <img src={EyeOn} />
                            ) : (
                              <img src={EyeOff} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ThemeProvider theme={theme}>
                    <Button
                      variant={"contained"}
                      color="secondary"
                      onClick={handleSignIn}
                      style={{
                        width: 320,
                        color: "#FFFFFF",
                        textTransform: "none"
                      }}
                    >
                      Verify
                    </Button>
                  </ThemeProvider>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ mr: 11 }}
                  justifyContent={"flex-start"}
                >
                  <span style={{}}>Didn't receive yet?</span>{" "}
                  <span
                    style={{
                      cursor: "pointer",
                      color: "#000099",
                      textDecoration: "underline"
                    }}
                    onClick={handleSendEmailOtp}
                  >
                    Resend Again
                  </span>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Box>
      </div>
    </>
  )
}

export default VerifyOtp
