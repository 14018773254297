import { createContext, useContext, useState } from "react"
import { API } from "../api"
import { toast } from "react-toastify"
import { deleteCookies } from "../utils/util"

const AuthContext = createContext({} as any)

const AuthProvider = ({ children }: any) => {
  const [isLoading, setIsLoading] = useState(false)
  const [regions, setRegions] = useState([])
  const [confirmEmailOtpResp, setConfirmEmailOtpResp] = useState([])
  const [subKpis, setSubKpis] = useState([])
  const [userType, setUserType] = useState(null)
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const sendEmailOtp = (payload: any) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.sendEmailOtp(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          toast.error(error?.response?.data?.message)
          console.log(error)
        })
        .finally(() => setIsLoading(false))
    })
  }

  const confirmEmailOtp = (payload: any) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.confirmEmailOtp(payload)
        .then(response => {
          setConfirmEmailOtpResp(response.data)
          resolve(response.data)
        })
        .catch(error => {
          toast.error(error?.response?.data?.message)
          console.log(error)
        })
        .finally(() => setIsLoading(false))
    })
  }

  const getRegions = () => {
    setIsLoading(true)
    API.getRegions()
      .then(response => {
        setRegions(response.data)
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const userRegister = (payload: any) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.register(payload)
        .then(response => {
          toast.success(response?.data?.message)
          resolve(response.data)
        })
        .catch(error => {
          toast.error(error?.response?.data?.message)
          console.log(error)
        })
        .finally(() => setIsLoading(false))
    })
  }

  const userLogin = (payload: any) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.login(payload)
        .then(response => {
          localStorage.setItem("token", response.data.token)
          localStorage.setItem("user", JSON.stringify(response.data.user))
          localStorage.setItem("company", JSON.stringify(response.data.company))
          setUserType(response.data?.user?.user_type)
          setIsAuthenticated(true);
          resolve(response.data.user)
        })
        .catch(error => {
          toast.error(error?.response?.data?.non_field_errors[0])
          console.log(error)
        })
        .finally(() => setIsLoading(false))
    })
  }

  const userLogout = () => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.logout()
        .then(response => {
          setIsAuthenticated(false);
          resolve(response)
        })
        .catch(error => console.log(error))
        .finally(() => {
          localStorage.removeItem("token")
          localStorage.removeItem("user")
          localStorage.removeItem("company")
          deleteCookies();
          setIsLoading(false)
        })
    })
  }

  const resetSendEmailOtp = (payload: any) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.resetSendEmailOtp(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          toast.error(error?.response?.data?.message)
          console.log(error)
        })
        .finally(() => setIsLoading(false))
    })
  }

  const resetConfirmEmailOtp = (payload: any) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.resetConfirmEmailOtp(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          toast.error(error?.response?.data?.message)
          console.log(error)
        })
        .finally(() => setIsLoading(false))
    })
  }

  const getSubKpiList = () => {
    setIsLoading(true)
    API.getSubKpiList()
      .then(response => {
        setSubKpis(response.data)
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const userResetPassword = (payload: any) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.resetUserPassword(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          toast.error(error?.response?.data?.message)
          console.log(error)
        })
        .finally(() => setIsLoading(false))
    })
  }

  const addKpi = (payload: any) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.addKpi(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          toast.error(error?.response?.data?.detail)
          console.log(error)
        })
        .finally(() => setIsLoading(false))
    })
  }

  const editKpi = (id: any, payload: any) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.editKpi(id, payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          toast.error(error?.response?.data?.detail)
          console.log(error)
        })
        .finally(() => setIsLoading(false))
    })
  }

  const getKpiList = (payload: any) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.getKpiList(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          toast.error(error?.response?.data?.message)
          console.log(error)
        })
        .finally(() => setIsLoading(false))
    })
  }

  const getKpi = (id: any) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.getKpi(id)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          toast.error(error?.response?.data?.message)
          console.log(error)
        })
        .finally(() => setIsLoading(false))
    })
  }

  const deleteKpi = (id: any) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.delKpi(id)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          toast.error(error?.response?.data?.message)
          console.log(error)
        })
        .finally(() => setIsLoading(false))
    })
  }

  const fetchReportData = (payload: any) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.fetchReportData(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          toast.error(error?.response?.data?.detail)
          console.log(error)
        })
        .finally(() => setIsLoading(false))
    })
  }

  const addOrEditReportData = (payload: any) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.addOrEditReportData(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          toast.error(error?.response?.data?.detail)
          console.log(error)
        })
        .finally(() => setIsLoading(false))
    })
  }

  const getCompanyList = () => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.getCompanyList()
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          toast.error(error?.response?.data?.message)
          console.log(error)
        })
        .finally(() => setIsLoading(false))
    })
  }

  const getScorecardData = (payload: any) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.getScorecard(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          toast.error(error?.response?.data?.message)
          console.log(error)
        })
        .finally(() => setIsLoading(false))
    })
  }

  const getCustomerList = () => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.getCustomerList()
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          toast.error(error?.response?.data?.message)
          console.log(error)
        })
        .finally(() => setIsLoading(false))
    })
  }

  const fetchChartData = (payload: any) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.fetchChartData(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          toast.error(error?.response?.data?.detail)
          console.log(error)
        })
        .finally(() => setIsLoading(false))
    })
  }

  const getUserList = () => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.getUsers()
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          toast.error(error?.response?.data?.message)
          console.log(error)
        })
        .finally(() => setIsLoading(false))
    })
  }

  const editUser = (id: any, payload: any) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.editUser(id, payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          toast.error(error?.response?.data?.detail)
          console.log(error)
        })
        .finally(() => setIsLoading(false))
    })
  }

  const deleteUser = (id: any) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.delUser(id)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          toast.error(error?.response?.data?.message)
          console.log(error)
        })
        .finally(() => setIsLoading(false))
    })
  }

  const getFeedbackList = () => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.getFeedbackList()
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          toast.error(error?.response?.data?.message)
          console.log(error)
        })
        .finally(() => setIsLoading(false))
    })
  }

  const postFeedback = (payload: any) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.postFeedback(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          toast.error(error?.response?.data?.detail)
          console.log(error)
        })
        .finally(() => setIsLoading(false))
    })
  }
  
  const replyFeedback = (payload: any) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.replyFeedback(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          toast.error(error?.response?.data?.detail)
          console.log(error)
        })
        .finally(() => setIsLoading(false))
    })
  }

  const uploadChartImage = (payload: any) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.uploadChartImage(payload)
        .then(response => {
          resolve(response.data)
          toast.success("screenshot captured")
        })
        .catch(error => {
          toast.error(error?.response?.data?.detail)
          console.log(error)
        })
        .finally(() => setIsLoading(false))
    })
  }

  const getContactUs = () => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.getContactUs()
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          toast.error(error?.response?.data?.message)
          console.log(error)
        })
        .finally(() => setIsLoading(false))
    })
  }
  const postDashboard = (payload: any) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.postDashboard(payload)
        .then(response => {
          resolve(response.data)
          toast.success("Data fetched.")
        })
        .catch(error => {
          toast.error(error?.response?.data?.detail)
          console.log(error)
        })
        .finally(() => setIsLoading(false))
    })
  }

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        userLogin,
        userType,
        sendEmailOtp,
        confirmEmailOtp,
        confirmEmailOtpResp,
        getRegions,
        regions,
        userRegister,
        userLogout,
        resetSendEmailOtp,
        resetConfirmEmailOtp,
        userResetPassword,
        getSubKpiList,
        subKpis,
        addKpi,
        editKpi,
        getKpi,
        getKpiList,
        getCompanyList,
        fetchReportData,
        getCustomerList,
        addOrEditReportData,
        deleteKpi,
        fetchChartData,
        getScorecardData,
        getUserList,
        editUser,
        deleteUser,
        getFeedbackList,
        postFeedback,
        replyFeedback,
        uploadChartImage,
        getContactUs,
        isAuthenticated,
        postDashboard
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => useContext(AuthContext)

export default AuthProvider
