import { ReactNode, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

type LayoutProps = {
  children: ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  const location = useLocation()
  const [showNavBar, setShowNavBar] = useState(false)

  useEffect(() => {
    if (
      location.pathname === "/login" ||
      location.pathname === "/register" ||
      location.pathname === "/verify-otp" ||
      location.pathname === "/email-verify-success" ||
      location.pathname === "/add-profile" ||
      location.pathname === "/register-success" ||
      location.pathname === "/forgot-password" ||
      location.pathname === "/reset-password" ||
      location.pathname === "/password-change-success" ||
      location.pathname?.includes('/image')
    ) {
      setShowNavBar(false)
    } else {
      setShowNavBar(true)
    }
  }, [location])

  return (
    <div>
      <main>{showNavBar && children}</main>
    </div>
  )
}

export default Layout
