import { useEffect, useState } from "react"
import { ReactComponent as AppLogo } from "../../assests/images/AppLogo.svg"
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  ThemeProvider,
  createTheme
} from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"
import { useAuthContext } from "../../context/AuthContext"
import EyeOn from "../../assests/images/eye_on.svg"
import EyeOff from "../../assests/images/eye_off.svg"
import "./SignIn.css";

const ResetPassword = () => {
  const location = useLocation();
  const nav = useNavigate();
  const { userResetPassword } = useAuthContext();
  const theme = createTheme({
    palette: {
      secondary: {
        main: "#000099"
      }
    }
  })

  const [values, setValues] = useState({
    new_password1: "",
    new_password2: "",
    showPassword1: false,
    showPassword2: false
  })
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [uid, setUid] = useState("")
  const [token, setToken] = useState("")

  useEffect(() => {
    setUid(location.state?.uid)
    setToken(location.state?.token)
  }, [])

  const handleChange =
    (prop: string) => (event: { target: { value: any } }) => {
      setValues({ ...values, [prop]: event.target.value })
    }

    const handleClickShowPassword1 = () => setValues({ ...values, showPassword1: !values.showPassword1});
    const handleMouseDownPassword1 = (event: any) => event.preventDefault();

    const handleClickShowPassword2 = () => setValues({ ...values, showPassword2: !values.showPassword2});
    const handleMouseDownPassword2 = (event: any) => event.preventDefault();

  const handleResetPassword = async (event: any) => {
    event.preventDefault();
    const validationErrors: { [key: string]: string } = {};
    
    // Password1 validation
    if (values.new_password1.trim() === '') {
      validationErrors.password1 = 'Password is required';
    } else if (values.new_password1.length < 8) {
      validationErrors.password1 = 'Password should be at least 8 characters long';
    }

       // Password2 validation
       if (values.new_password1 !== values.new_password2) {
        validationErrors.password2 = 'Passwords do not match.';
      }
 
     if (Object.keys(validationErrors).length > 0) {
       setErrors(validationErrors);
     } else {
     userResetPassword({ new_password1: values.new_password1, new_password2: values.new_password2, uid, token }).then(() => nav("/password-change-success"))
     }
  }

  return (
    <>
    <div className="page-container">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
          px: 2
        }}
      >
        <Paper
          sx={{
            p: 2,
            textAlign: "center",
            maxWidth: "400px",
            width: "100%"
          }}
          style={{
            borderColor: "#000099",
            borderWidth: 2,
            borderStyle: "solid",
            borderRadius: 8
          }}
        >
          <div className="grid-container">
            <Grid
              container
              direction={"column"}
              justifyItems={"center"}
              alignItems={"center"}
            >
              <Grid item xs={12}>
                <AppLogo />
                <Box className="sign-in-title">Reset Password</Box>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="New Password"
                  variant="outlined"
                  className="sign-in-field"
                  size="small"
                  style={{
                    width: 320,
                    marginBottom: 0,
                    borderColor: "#FFFFFF"
                  }}
                  type={values.showPassword1 ? "text" : "password"}
                  value={values.new_password1}
                  onChange={handleChange("new_password1")}
                  margin="normal"
                  error={!!errors.password1}
                  helperText={errors.password1}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword1}
                          onMouseDown={handleMouseDownPassword1}
                          edge="end"
                        >
                          {values.showPassword1 ? <img src={EyeOn} /> :  <img src={EyeOff} />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Confirm New Password"
                  variant="outlined"
                  className="sign-in-field"
                  size="small"
                  style={{
                    width: 320,
                    marginBottom: 0,
                    borderColor: "#FFFFFF"
                  }}
                  type={values.showPassword2 ? "text" : "password"}
                  value={values.new_password2}
                  onChange={handleChange("new_password2")}
                  margin="normal"
                  error={!!errors.password2}
                  helperText={errors.password2}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword2}
                          onMouseDown={handleMouseDownPassword2}
                          edge="end"
                        >
                          {values.showPassword2 ? <img src={EyeOn} /> :  <img src={EyeOff} />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ pt: 2, pb: 2 }}>
                <ThemeProvider theme={theme}>
                  <Button
                    variant={"contained"}
                    color="secondary"
                    onClick={handleResetPassword}
                    style={{
                      width: 320,
                      color: "#FFFFFF",
                      textTransform: "none"
                    }}
                  >
                    Update Password
                  </Button>
                </ThemeProvider>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Box>
      </div>
    </>
  )
}

export default ResetPassword
