import * as React from "react"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import { Grid, Typography } from "@mui/material"
import Sidebar from "../sidebar/Sidebar"
import "./Navbar.css"
import { ReactComponent as SmallAppLogo } from "../../assests/images/logo_small.svg"
import { getUserType } from "../../utils/util"

export default function Navbar() {
  const [userEmail, setUserEmail] = React.useState("")
  const [userType, setUserType] = React.useState("")

  React.useEffect(() => {
    if (localStorage.getItem("user")) {
      const userDataString = localStorage.getItem("user") as string;
     const userData = JSON.parse(userDataString)
     setUserEmail(userData?.email)
     setUserType(userData?.user_type)
   }
  }, [])

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="fixed"
          style={{
            background: "#FFFFFF",
            height: 100,
            justifyContent: "center"
          }}
        >
          <Toolbar>
            <Grid container sx={{ display: "flex", alignItems: "center" }}>
              <Grid item xs={2}>
                <SmallAppLogo />
              </Grid>
            </Grid>
              <Grid item xs={10} sx={{ display: "flex", alignItems: "flex-start" }}>
                <div>
                <Typography sx={{ color: "black", fontWeight: "bold" }}>
                    {userEmail}
                </Typography>
                <Typography sx={{ color: "black" }}>
                    {getUserType(Number(userType))}
                </Typography>
                </div>
              </Grid>
          </Toolbar>
        </AppBar>
        <Sidebar></Sidebar>
      </Box>
    </>
  )
}
