import { ThemeProvider } from "@emotion/react"
import { createTheme, Grid, Box, Button } from "@mui/material"
import { useState } from "react"
import { subKpiType } from "../../api"
import InputFieldData from "./inputFieldData"
import { checkShowCompanyAndShowCustomer, getFillRate, getUserData, isDecimalFillRate } from "../../utils/util"

const CreateKpiReportData = (props: any) => {
  const {
    selectedKpi,
    kpiId,
    subKpi,
    key,
    date,
    customer,
    company,
    reportData,
    handleSaveEditRow,
    index,
    isActive,
    handleToggle,
    useCompany,
  } = props
  const [monthValues, setMonthValues] = useState<{ kpi: number; sub_kpi: number; date: string; fill_rate: any; other_fill_rate: any, company: any; customer: any; }[]>([]);
  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              borderRadius: 0
            },
            width: "100%"
          }
        }
      }
    }
  })

  const months = [
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
    "Jan",
    "Feb",
    "Mar"
  ]
  const [toggleEdit, setToggleEdit] = useState(false)

  const getMonthlyData = (month: string, subKpi: any, selectedCustomer: any, selectedCompany: any) => {
    // Extract the year from the start date
    const [startYear] = date?.startDate?.split("-")
    // Extract the year and month from the end date
    const [endYear] = date?.endDate?.split("-")
    // Generate the dynamic date based on the selected month
    let generatedDate: string = ""

    if (month === "Apr") {
      generatedDate = `${startYear}-04-01`
    } else if (month === "May") {
      generatedDate = `${startYear}-05-01`
    } else if (month === "Jun") {
      generatedDate = `${startYear}-06-01`
    } else if (month === "Jul") {
      generatedDate = `${startYear}-07-01`
    } else if (month === "Aug") {
      generatedDate = `${startYear}-08-01`
    } else if (month === "Sep") {
      generatedDate = `${startYear}-09-01`
    } else if (month === "Oct") {
      generatedDate = `${startYear}-10-01`
    } else if (month === "Nov") {
      generatedDate = `${startYear}-11-01`
    } else if (month === "Dec") {
      generatedDate = `${startYear}-12-01`
    } else if (month === "Jan") {
      generatedDate = `${endYear}-01-01`
    } else if (month === "Feb") {
      generatedDate = `${endYear}-02-01`
    } else if (month === "Mar") {
      generatedDate = `${endYear}-03-01`
    }

    let fill_rate = null
    reportData.map((report: any) => {
      const [showCompany, showCustomer] = checkShowCompanyAndShowCustomer(
        selectedKpi,
        subKpi,
        getUserData()?.user_type
      )
      if (report?.date === generatedDate && report?.sub_kpi === subKpi.id &&
        (!selectedCustomer || !showCustomer || selectedCustomer?.toString() === report?.customer?.toString()) &&
        ((!useCompany && (!selectedCompany || !showCompany)) || selectedCompany?.toString() === report?.company?.toString())
      ) {
        fill_rate = getFillRate(subKpi, report)
      }
    })
    return { fill_rate, generatedDate }
  }

  const getSubKpiType = () => {
    if (subKpi.type === 1) {
      return subKpiType[0].label
    } else if (subKpi.type === 2) {
      return subKpiType[1].label
    } else return subKpiType[2].label
  }

  const handleToggleEdit = () => {
    setToggleEdit(!toggleEdit)
  }

  const handleCancelEditRow = () => {
    handleToggle(index)
  }

  const handleTextField = (
    value: any,
    kpiId: any,
    subKpi: any,
    getMonthlyData: any,
    company: any,
    customer: any
  ) => {
    const [showCompany, showCustomer] = checkShowCompanyAndShowCustomer(
      selectedKpi, subKpi, getUserData()?.user_type
    )
    const subKpiId = subKpi?.id;
    const payloadObj = {
      kpi: kpiId,
      sub_kpi: subKpiId,
      date: getMonthlyData?.generatedDate,
      fill_rate: isDecimalFillRate(subKpi?.data_type) ? value : null,
      other_fill_rate: isDecimalFillRate(subKpi?.data_type) ? null : value,
      company: (useCompany || showCompany ? company : null) || null,
      customer: (showCustomer ? customer : null) || null
    }
    let savePayloadObj = payloadObj

    reportData &&
      reportData?.map((report: any) => {
        if (
          report?.kpi === savePayloadObj.kpi &&
          report?.sub_kpi === savePayloadObj.sub_kpi &&
          report?.date === savePayloadObj.date &&
          report?.id
        ) {
          savePayloadObj = {
            ...payloadObj,
            id: report.id,
          } as typeof payloadObj & { id: any };
        }
      })
    // Find the index of the existing value in the array
    const existingIndex: any = monthValues.findIndex(
      (item: any) => item.date === getMonthlyData?.generatedDate
    )

    if (existingIndex !== -1) {
      // If the value already exists, update it in the array
      setMonthValues(prevValues => {
        const updatedValues: any = [...prevValues]
        updatedValues[existingIndex] = savePayloadObj
        return updatedValues
      })
    } else {
      // If the value doesn't exist, add it to the array
      setMonthValues(prevValues => [...prevValues, savePayloadObj])
    }
  }
  const handleSaveRow = () => {
    const processedMonthValues = monthValues?.map((data: any) => {
      const fillRate = getFillRate(subKpi, data);
      data.fill_rate = isDecimalFillRate(subKpi?.data_type) ? fillRate : null;
      data.other_fill_rate = isDecimalFillRate(subKpi?.data_type) ? null : fillRate;

      return data;
    })

    setMonthValues([])
    handleSaveEditRow(
      processedMonthValues,
      date,
      kpiId,
      subKpi.id,
      company,
      customer,
      selectedKpi,
    )
    handleToggle(index)
  }

  return (
    <>
      <Grid container display={"flex"} flexDirection={"row"} sx={{ mt: 1 }}>
        <ThemeProvider theme={theme}>
          <Box border="1px solid #E0E0E0">
            <Grid container>
              <Grid item xs={12} style={{ background: "#E4EAFD" }}>
                <Grid
                  container
                  flex-direction={"column"}
                  alignItems={"center"}
                  sx={{ pb: 1 }}
                >
                  <Grid
                    item
                    xs={5}
                    style={{
                      fontSize: 20
                    }}
                  >
                    <h5>{subKpi.name}</h5>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{
                      fontSize: 18,
                      display: "flex",
                      justifyContent: "flex-start"
                    }}
                  >
                    {getSubKpiType()}
                  </Grid>
                  {!isActive ? (
                    <Grid
                      item
                      xs={4}
                      display={"flex"}
                      justifyContent={"flex-end"}
                    >
                      <Button
                        onClick={() => handleToggle(index)}
                        variant="contained"
                        style={{ background: "#000099" }}
                      >
                        Edit
                      </Button>
                    </Grid>
                  ) : null}
                  {isActive ? (
                    <>
                      <Grid
                        item
                        xs={3}
                        sx={{ pr: 4 }}
                        justifyContent={"flex-end"}
                        display={"flex"}
                      >
                        <Button
                          onClick={handleCancelEditRow}
                          variant="contained"
                          style={{ background: "#F8F9FC", color: "#000000" }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item xs={1} display={"flex"}>
                        <Button
                          onClick={handleSaveRow}
                          variant="contained"
                          style={{ background: "#000099" }}
                        >
                          Save
                        </Button>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  {months.map((month, index) => (
                    <div
                      key={`Month-${month}-${index}-${kpiId}-${subKpi}`}
                      style={{
                        padding: "0px",
                        fontWeight: 500,
                        fontSize: 16,
                        borderBottom: `1px solid ${index === months.length - 1 ? "transparent" : "none"
                          }`
                      }}
                    >
                      {month}
                    </div>
                  ))}
                </div>
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {months.map((month, index) => (
                    <div
                      key={`fill-rate-${month}-${index}-${kpiId}-${subKpi}`}
                      style={{
                        padding: "0px",
                        borderBottom: `1px solid ${index === months.length - 1 ? "transparent" : "none"
                          }`
                      }}
                    >
                      <InputFieldData
                        disabledValue={!isActive}
                        month={month}
                        kpiId={kpiId}
                        subKpi={subKpi}
                        getMonthlyData={getMonthlyData(month, subKpi, customer, company)}
                        company={company}
                        customer={customer}
                        reportData={reportData}
                        handleTextField={handleTextField}
                      />
                    </div>
                  ))}
                </div>
              </Grid>
            </Grid>
          </Box>
        </ThemeProvider>
      </Grid>
    </>
  )
}

export default CreateKpiReportData
