import { TextField } from "@mui/material"
import { useState, useEffect } from "react"
import { DATA_TYPE_PERCENTAGE } from "../../api"
import { getFillRate } from "../../utils/util"

const InputFieldData = (props: any) => {
  const {
    disabledValue,
    kpiId,
    subKpi,
    getMonthlyData,
    company,
    customer,
    handleTextField
  } = props
  const [inputValue, setInputValue] = useState(
    getMonthlyData?.fill_rate || null
  )

  useEffect(() => {
    if (getMonthlyData?.fill_rate) setInputValue(getMonthlyData?.fill_rate)
  }, [getMonthlyData?.fill_rate])

  const handleChange = (
    value: any,
    kpiId: any,
    subKpi: any,
    getMonthlyData: any,
    company: any,
    customer: any,
    skipParse: boolean
  ) => {
    const fill_rate = value?.trim() || null
    value = getFillRate(
      subKpi,
      { fill_rate, other_fill_rate: fill_rate },
      inputValue,
      skipParse
    )
    setInputValue(value)
    handleTextField(value, kpiId, subKpi, getMonthlyData, company, customer)
  }

  return (
    <TextField
      style={{ borderRadius: "0px" }}
      variant="outlined"
      InputProps={{
        endAdornment:
          subKpi?.data_type === DATA_TYPE_PERCENTAGE ? "%" : undefined
      }}
      fullWidth
      aria-readonly
      disabled={disabledValue}
      value={inputValue}
      onChange={event => {
        handleChange(
          event?.target.value,
          kpiId,
          subKpi,
          getMonthlyData,
          company,
          customer,
          true
        )
      }}
      onBlur={event => {
        handleChange(
          event?.target.value,
          kpiId,
          subKpi,
          getMonthlyData,
          company,
          customer,
          false
        )
      }}
    />
  )
}

export default InputFieldData
