import React, { Fragment } from "react"
import { Typography, Paper } from "@mui/material"
import { styled } from "@mui/material/styles"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Tooltip from "@mui/material/Tooltip"
import {
  useReactTable,
  getCoreRowModel,
  flexRender
} from "@tanstack/react-table"
import moment from "moment"

import SparkLineChart from "./SparkLineChart"
import {
  getPriority,
  getSubKpiDataType,
  getSubKpiDataTypeSymbol,
  getSubKpiType
} from "../../utils/util"

const HeaderTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#000000DE",
    fontSize: "0.875rem",
    fontWeight: "700",
    backgroundColor: "#f6f5fb",
    padding: "0.625rem 0.5rem",

    ":not(:last-of-type) .MuiTypography-root": {
      width: "100%",
      position: 'relative',

      '&::after': {
        content: "''",
        width: '100%',
        height: '1.75rem',
        left: 0,
        position: 'absolute',
        borderRight: "2px solid #E0E0E0",
        top: '50%',
        transform: "translateY(-50%)"
      }
    }
  }
}))

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    color: "#000000DE",
    fontSize: "0.875rem",
    padding: "0.625rem 0.5rem"
  }
}))

const ScoreCardTable = (props: any) => {
  const { scorecardData, startDate, endDate, middleDate, kpiList } = props
  const subKpiDataByName: any = kpiList?.reduce((acc: any, kpi: any) => {
    acc[kpi?.performance_indicator] ||= {};

    kpi?.sub_kpi_details?.map((subKpiList: any) =>
      acc[kpi?.performance_indicator][subKpiList?.name] = subKpiList
    );

    return acc;
  }, {});
  const months = [
    moment(startDate).format("MMMM"),
    moment(middleDate).format("MMMM"),
    moment(endDate).format("MMMM")
  ]

  const columnPinning = React.useMemo(() => {
    return {
      right: scorecardData?.[0]?.distributors_report?.map(
        (distributorData: any) => {
          return "distributor-" + distributorData.distributor
        }
      )
    }
  }, [scorecardData])

  const columns = React.useMemo(() => {
    const distributorColumns =
      scorecardData?.[0]?.distributors_report?.map(
        (distributorData: any, index: any) => {
          return {
            accessorFn: (row: any) => row?.distributors_report?.[index],
            id: "distributor-" + distributorData?.distributor,
            header: () => (
              <Tooltip title={distributorData?.distributor} placement="top">
                <Typography variant="subtitle1" component="div">
                  {distributorData?.distributor}
                </Typography>
              </Tooltip>
            ),
            enablePinning: true,
            size: 80,
            cell: (rowProps: any) => {
              const { row, getValue } = rowProps
              const subKpi = row?.getValue("sub_kpi");
              const kipName = row?.getValue("kpi");
              const subKpiData = subKpiDataByName?.[kipName]?.[subKpi];
              const type = getSubKpiType(subKpiData?.type)

              if (type !== 'Overall' && distributorData?.distributor === 'OEM') {
                return 'NA'
              } else if (type === 'Overall' && distributorData?.distributor !== 'OEM') {
                return 'NA'
              }
              const chartData = getValue?.()?.data || [];
              const endMonth = moment(endDate).format("MMMM");
              const hasData = months?.some(
                (month: any) => chartData?.find((monthData: any) => monthData[month])
              )
              const endMonthData = chartData?.find((monthData: any) => monthData[endMonth])?.[endMonth]

              return (
                <div style={{ margin: "auto", position: "relative" }}>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{
                      textAlign: "center",
                      position: "absolute",
                      top: "-1.3rem",
                      width: "100%"
                    }}
                  >
                    {endMonthData}
                  </Typography>
                  <div style={hasData ? { marginTop: "1rem" } : {}}>
                    <SparkLineChart
                      data={chartData}
                      target={row?.getValue("target")}
                      months={months}
                      targetMonth={moment(endDate).format("MMMM")}
                    />
                  </div>
                </div>
              )
            }
          }
        }
      ) || {}
    return [
      {
        id: "priority",
        header: () => (
          <Typography variant="subtitle1" component="div">
            Priority
          </Typography>
        ),
        accessorKey: "priority",
        size: 80,
        cell: (rowProps: any) => {
          const { getValue } = rowProps

          return (
            <Typography
              variant="subtitle1"
              component="div"
              sx={{ textTransform: "capitalize" }}
            >
              {getPriority(getValue?.())?.toLowerCase()}
            </Typography>
          )
        }
      },
      {
        id: "id",
        header: () => (
          <Typography
            variant="subtitle1"
            component="div"
            sx={{
              width: "100%",
              borderRight: "2px solid #E0E0E0"
            }}
          >
            ID
          </Typography>
        ),
        accessorKey: "id",
        size: 60,
        cell: (rowProps: any) => {
          const { getValue } = rowProps

          return (
            <Typography variant="subtitle1" component="div">
              {getValue?.()}
            </Typography>
          )
        }
      },
      {
        id: "kpi",
        header: () => (
          <Typography variant="subtitle1" component="div">
            KPI
          </Typography>
        ),
        accessorKey: "kpi",
        size: 180,
        cell: (rowProps: any) => {
          const { getValue } = rowProps

          return (
            <Tooltip title={getValue?.()} placement="top">
              <Typography variant="subtitle1" component="div">
                {getValue?.()}
              </Typography>
            </Tooltip>
          )
        }
      },
      {
        id: "sub_kpi",
        header: () => (
          <Typography variant="subtitle1" component="div">
            Sub KPI
          </Typography>
        ),
        accessorKey: "sub_kpi",
        size: 200,
        cell: (rowProps: any) => {
          const { getValue } = rowProps

          return (
            <Tooltip title={getValue?.()} placement="top">
              <Typography variant="subtitle1" component="div">
                {getValue?.()}
              </Typography>
            </Tooltip>
          )
        }
      },
      {
        id: "target",
        header: () => (
          <Typography variant="subtitle1" component="div">
            Target
          </Typography>
        ),
        accessorKey: "target",
        size: 60,
        cell: (rowProps: any) => {
          const { getValue } = rowProps

          return (
            <Typography variant="subtitle1" component="div">
              {getValue?.()}
            </Typography>
          )
        }
      },
      {
        id: "unit",
        header: () => (
          <Typography variant="subtitle1" component="div">
            Unit
          </Typography>
        ),
        accessorKey: "unit",
        size: 50,
        cell: (rowProps: any) => {
          const { getValue } = rowProps

          return (
            <Tooltip title={getSubKpiDataType(getValue?.())} placement="top">
              <Typography variant="subtitle1" component="div">
                {getSubKpiDataTypeSymbol(getValue?.())}
              </Typography>
            </Tooltip>
          )
        }
      },
      ...distributorColumns
    ]
  }, [scorecardData, endDate])

  const table = useReactTable({
    data: scorecardData,
    columns,
    state: {
      columnPinning
    },
    getCoreRowModel: getCoreRowModel()
  })

  return (
    <TableContainer component={Paper} sx={{
      marginTop: "1rem",
      maxHeight: "calc(100vh - 271px)",
      scrollbarWidth: "thin",
      "&::-webkit-scrollbar": {
        width: "10px",
        height: "10px"
      },
      '&:: -webkit-scrollbar-track': {
        background: '#f1f1f1',
      },

      '&::-webkit-scrollbar-thumb': {
        background: '#c1c1c1',
        borderRadius: '10px'
      }
    }}>
      < Table stickyHeader sx={{ tableLayout: "fixed" }
      }>
        <TableHead>
          {table.getHeaderGroups().map(headerGroup => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                const index = columnPinning?.right?.indexOf(header?.column?.id)
                const total = table?.getRightTotalSize()
                const position =
                  total +
                  (columnPinning?.right?.length - index - 1) * 16 -
                  header?.column?.getStart("right") -
                  header?.column?.getSize()
                return (
                  <HeaderTableCell
                    key={header.id}
                    colSpan={header.colSpan}
                    sx={{
                      width: header?.column?.getSize(),
                      ...(columnPinning?.right?.includes(header.id)
                        ? {
                          right: position + "px",
                          position: "sticky",
                          zIndex: 3,
                          background: "#FFFFFF",
                          top: 0,
                          boxShadow:
                            index === 0
                              ? "rgba(0, 0, 0, 0.21) -2px 0px 4px -2px"
                              : "inherit"
                        }
                        : {})
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  </HeaderTableCell>
                )
              })}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {table.getRowModel().rows.map(row => {
            return (
              <Fragment key={row.id}>
                <TableRow>
                  {row.getVisibleCells().map(cell => {
                    const total = table?.getRightTotalSize()
                    const index = columnPinning?.right?.indexOf(
                      cell?.column?.id
                    )
                    const position =
                      total +
                      (columnPinning?.right?.length - index - 1) * 16 -
                      cell?.column?.getStart("right") -
                      cell?.column?.getSize()
                    return (
                      <StyledTableCell
                        key={cell.id}
                        sx={{
                          width: cell?.column?.getSize(),
                          ...(columnPinning?.right?.includes(cell?.column?.id)
                            ? {
                              right: position + "px",
                              position: "sticky",
                              zIndex: 2,
                              background: "#FFFFFF",
                              boxShadow:
                                index === 0
                                  ? "rgba(0, 0, 0, 0.21) -2px 0px 4px -2px"
                                  : "inherit"
                            }
                            : {})
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </StyledTableCell>
                    )
                  })}
                </TableRow>
              </Fragment>
            )
          })}
        </TableBody>
      </Table >
    </TableContainer >
  )
}

export default ScoreCardTable
