import React from "react"
import Box from "@mui/material/Box"
import Drawer from "@mui/material/Drawer"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { useLocation, useNavigate } from "react-router-dom"
import { ThemeProvider, createTheme } from "@mui/material"
import ListAltIcon from '@mui/icons-material/ListAlt';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import GradingIcon from '@mui/icons-material/Grading';
import PollIcon from '@mui/icons-material/Poll';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FeedbackIcon from '@mui/icons-material/Feedback';

import { ReactComponent as LogoutIcon } from "../../assests/images/logout.svg"
import "./sidebar.css"
import { useAuthContext } from "../../context/AuthContext"

const menuItems = [
  {
    key: 'create-dashboard',
    label: 'Dashboard',
    path: '/create-dashboard',
    icon: QueryStatsIcon
  },
  {
    key: 'kpi',
    label: 'KPI',
    path: '/kpi-list',
    icon: ListAltIcon,
    allowedUserType: [1]
  },
  {
    key: 'view-reports',
    label: "View Reports",
    path: '/kpi-report',
    icon: QueryStatsIcon,
  },
  {
    key: 'generate-scorecard',
    label: "Generate Scorecard",
    path: '/generate-scorecard',
    icon: GradingIcon,
  },
  {
    key: 'generate-chart',
    label: "Generate Chart",
    path: '/generate-chart',
    icon: PollIcon,
  },
  {
    key: 'users',
    label: 'Users',
    path: '/users',
    icon: AccountCircleIcon,
    allowedUserType: [1]
  },
  {
    key: 'feedback',
    label: 'Feedback',
    path: '/feedback',
    icon: FeedbackIcon,
  }
];

export default function Sidebar() {
  const nav = useNavigate()
  const location = useLocation()
  const { userLogout } = useAuthContext()
  let userType: any = null
  if (localStorage.getItem("user")) {
     const userDataString = localStorage.getItem("user") as string;
    const userData = JSON.parse(userDataString)
    userType = userData?.user_type
  }
  const theme = createTheme({
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: "#140A9A",
            color: "#FFFFFF",
            width: "220px",
            marginTop: "100px",
            paddingLeft: "12px"
          }
        }
      }
    }
  })

  const handleLogout = () => {
    userLogout().then(() => nav("/login"))
  }

  const handleListItemClick = (event: any, path: string) => {
    nav(path)
  }

  const list = () => (
    <div className="sidebar-wrapper">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "calc(100vh - 100px)"
        }}
        role="presentation"
      >
        <List>
          {
          menuItems?.filter(
            item => item?.allowedUserType?.length
              ? item?.allowedUserType?.includes(userType) : true
          ).map(item => {
            const active = location.pathname === item?.path;
            const IconComponent = item?.icon;
            return <ListItem
                key={item?.key}
                disablePadding
                className={active ? "selected" : ""}
              >
                <ListItemButton
                  selected={active}
                  onClick={event => handleListItemClick(event, item?.path)}
                >
                  <ListItemIcon sx={{ minWidth: 45 }}>
                    <IconComponent
                      style={{ height: "20px", width: "20px" }}
                      sx={{ color: active ? '#140A9A' : "#FFFFFF" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ fontSize: "13px" }}
                    primary={item?.label}
                  />
                </ListItemButton>
              </ListItem>
            })
        }
        </List>
        <div className="sidebar-footer">
          <ListItem key={"Logout"} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <LogoutIcon style={{ height: "20px", width: "20px" }} />
              </ListItemIcon>
              <ListItemText
                onClick={handleLogout}
                primaryTypographyProps={{ fontSize: "14px" }}
                primary="Logout"
              />
            </ListItemButton>
          </ListItem>
        </div>
      </Box>
    </div>
  )

  return (
    <ThemeProvider theme={theme}>
      <div style={{ color: "#2A1E5C" }}>
        <Drawer anchor={"left"} variant="permanent">
          {list()}
        </Drawer>
      </div>
    </ThemeProvider>
  )
}
