import { Button, Grid, Typography, Box } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useAuthContext } from "../../context/AuthContext"
import { useEffect, useState } from "react"
import KpiListTable from "./KpiListTable"
import DeletePopUp from "./DeletePopup"
import { getUserData } from "../../utils/util"

const KpiReportList = () => {
  const nav = useNavigate()
  const { getKpiList, deleteKpi } = useAuthContext()
  const [userType, setUserType] = useState("")
  const [kpiList, setKpiList] = useState([])
  const [openDeletePopUp, setOpenDeletePopUp] = useState({
    open: false,
    kpiName: "",
    kpiId: null
  })
  const handleAddNewKPI = () => {
    nav("/create-kpi")
  }

  useEffect(() => {
    setUserType(getUserData()?.user_type)
    getKpiList().then((resp: any) => {
      setKpiList(resp)
    })
  }, [])

  interface Data {
    id: number | null;
    performance_indicator: string | null;
    category: string | null;
    priority: string | null;
    kpi_number: string | null;
    permission: string | null;
    status: string | null;
  }

  interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
  }

  const headCells: HeadCell[] = [
    {
      id: "performance_indicator",
      numeric: false,
      disablePadding: true,
      label: "Title/Performance Indicator"
    },
    {
      id: "category",
      numeric: false,
      disablePadding: false,
      label: "Category"
    },
    {
      id: "priority",
      numeric: false,
      disablePadding: false,
      label: "Priority"
    },
    {
      id: "kpi_number",
      numeric: false,
      disablePadding: false,
      label: "KPI #"
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status"
    },
    {
      id: "permission",
      numeric: false,
      disablePadding: false,
      label: "Permission"
    },
    {
      id: "performance_indicator",
      numeric: false,
      disablePadding: false,
      label: "Action"
    }
  ]

  const handleViewRow = (id: any) => {
    nav(`/view-kpi/${id}`, {
      state: { kpiId: id }
    })
  }

  const handleEditRow = (id: any) => {
    nav(`/edit-kpi/${id}`, {
      state: { mode: "edit" }
    })
  }

  const handleDeleteRow = (id: any, performance_indicator: any) => {
    setOpenDeletePopUp({
      ...openDeletePopUp,
      open: true,
      kpiName: performance_indicator,
      kpiId: id
    })
  }

  const handleCloseDeletePopUp = () => {
    setOpenDeletePopUp({
      ...openDeletePopUp,
      open: false,
      kpiName: "",
      kpiId: null
    })
  }

  const handleDeleteKpi = (kpiId: any) => {
    deleteKpi(kpiId).then(() => {
      setOpenDeletePopUp({
        ...openDeletePopUp,
        open: false,
        kpiName: "",
        kpiId: null
      })
      toast.success("KPI sucessfully deleted")
      getKpiList().then((response: any) => {
        setKpiList(response)
      })
    })
  }

  return (
    <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          px: 2,
          ml: 30,
          mt: 15,
          mb: 2,
          backgroundColor: "#F8F9FC"
        }}
      >
      <Grid container display={"flex"} flexDirection={"row"}>
        <Grid item xs={6} display={"flex"} justifyContent={"flex-start"}>
          <h2>KPI</h2>
        </Grid>
        <Grid
          item
          xs={5}
          display={"flex"}
          justifyContent={"flex-end"}
          sx={{ height: "40px" }}
        >
            <Button
              onClick={handleAddNewKPI}
              variant="contained"
              style={{ background: "#000099", textTransform: "capitalize" }}
            >
              Add New KPI
            </Button>
        </Grid>
        <Grid container display={"flex"} flexDirection={"row"} sx={{ mt: 2 }}>
          <Grid item xs={12} display={"flex"} justifyContent={"flex-start"}>
            <Box padding={2}>
              {kpiList && kpiList.length ? (
                <KpiListTable
                  rows={kpiList}
                  headers={headCells}
                  handleDelete={(id, performance_indicator) =>
                    handleDeleteRow(id, performance_indicator)
                  }
                  handleView={id => handleViewRow(id)}
                  handleEdit={id => handleEditRow(id)}
                  userType={userType}
                />
              ) : null}
            </Box>
          </Grid>
        </Grid>
        {openDeletePopUp?.open && (
          <DeletePopUp
            open={true}
            kpiId={openDeletePopUp?.kpiId}
            kpiName={openDeletePopUp?.kpiName}
            handleCloseDeletePopUp={handleCloseDeletePopUp}
            handleDelete={handleDeleteKpi}
          ></DeletePopUp>
        )}
      </Grid>
    </Box>
  )
}

export default KpiReportList
