import Button from "@mui/material/Button"
import { Box, Grid, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { useAuthContext } from "../../context/AuthContext"
import { toast } from "react-toastify"

export default function CreateFeedback(props: any) {
  const { details } = props
  const { postFeedback, getContactUs } = useAuthContext()

  const [values, setValues] = useState({
    subject: "",
    text: "",
    email: ""
  })

  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [userType, setUserType] = useState("")
  const [contactUs, setContactUs] = useState([] as any)

  useEffect(() => {
    if (localStorage.getItem("user")) {
       const userDataString = localStorage.getItem("user") as string;
      const userData = JSON.parse(userDataString)
      setUserType(userData?.email)
    }
    getContactUs().then((resp: any) => {
      setContactUs(resp[0])
    })
  }, [])

  useEffect(() => {
    setValues({ ...values, email: userType })
  }, [userType])

  useEffect(() => {
    setValues({
      ...values,
      subject: details?.subject,
      text: details?.text
    })
  }, [details])

  const handleChange =
    (prop: string) => (event: { target: { value: any } }) => {
      setValues({ ...values, [prop]: event.target.value })
    }

  const handleCancel = () => {
    setValues({ ...values, subject: "", text: "", email: "" })
    setErrors({})
  }

  const handleSendFeedback = () => {
    // Validate email
    const validationErrors: { [key: string]: string } = {}
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (values?.email?.trim() === "") {
      validationErrors.email = "Email is required"
    } else if (!emailRegex.test(values.email)) {
      validationErrors.email = "Invalid email format"
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
    } else {
      if (values?.email && values?.subject && values?.text) {
        postFeedback(values).then(() => {
          toast.success("Feedback sent successfully")
          setValues({ ...values, subject: "", text: "", email: "" })
          setErrors({})
        })
      }
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          px: 2,
          ml: 30,
          mt: 15,
          mb: 2,
          backgroundColor: "#F8F9FC"
        }}
      >
        <Grid container display={"flex"} flexDirection={"row"} sx={{ mt: 1 }}>
          <Grid item xs={2} display={"flex"} justifyContent={"flex-start"}>
            <h2>User Feedback</h2>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="subject"
              label="Subject"
              value={values?.subject}
              fullWidth
              sx={{ mb: 2 }}
              onChange={handleChange("subject")}
              error={!!errors.subject}
              helperText={errors.subject}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="feedback"
              label="Feedback"
              value={values?.text}
              fullWidth
              multiline
              rows={3}
              maxRows={5}
              onChange={handleChange("text")}
              sx={{ mb: 2 }}
              error={!!errors.text}
              helperText={errors.text}
            />
          </Grid>
          <Grid container display={"flex"} sx={{ mt: 2, mb: 2 }}>
            <Grid
              item
              xs={5}
              sx={{ pr: 1 }}
              justifyContent={"flex-end"}
              display={"flex"}
            >
              <Button
                onClick={handleCancel}
                variant="contained"
                style={{ background: "#F8F9FC", color: "#000000" }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} display={"flex"}>
              <Button
                onClick={handleSendFeedback}
                variant="contained"
                style={{ background: "#000099" }}
              >
                Send Feedback
              </Button>{" "}
            </Grid>
          </Grid>
          <Grid
            item
            xs={6}
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"flex-end"}
            sx={{ m: 2 }}
          >
            <div>
              <b>Contact us</b>
              <br />
              <em>{contactUs?.email ? `Email: ${contactUs?.email}` : ""}</em>
              <br />
              <em>
                {contactUs?.phone_number
                  ? `Phone: ${contactUs?.phone_number}`
                  : ""}
              </em>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
